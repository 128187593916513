import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getSubtitleLanguage = async () => {
    const token = localStorage.getItem('token') || null
    return await axios.get(`${API_URL}/user/get-languages`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const uploadSubtitle = async (obj) => {
    const token = localStorage.getItem('token') || null
    return await axios.post(`${API_URL}/user/upload-video-subtitle`, obj, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const getVideoSubtitle = async (videoId) => {
    const token = localStorage.getItem('token') || null
    return await axios.get(`${API_URL}/user/get-video-subtitles/${videoId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};

export const deleteSubtitle = async (videoId) => {
    const token = localStorage.getItem('token') || null
    return await axios.delete(`${API_URL}/user/delete-subtitle/${videoId}`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    });
};