import React, { useEffect, useState, useRef } from "react";
import "./VideoPlayer.css";
import "./plyr-plugin-thumbnail"
import { useParams } from "react-router-dom";
import { getFileUrl, getVideoFileDetails, getVideoSignedUrl, getVideoThumbnail, videoViewCount } from "../../features/files/fileSlice";
import { useDispatch, useSelector } from "react-redux";
import { getIpAddressService } from "../../features/ipInfo/ipInfo";
import { getVideoSettings } from "../../features/videoSetting/videosettingSlice";
import { useDetectAdBlock } from "adblock-detect-react";

const VideoPlayer = () => {
  const adBlockDetected = useDetectAdBlock();

  useEffect(() => {
    if (adBlockDetected) {
      console.log('ad block detected');
    }
  }, [adBlockDetected]);
  const [videoUrl, setVideoUrl] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();
  const { gettedfileData, getFileUrlStatus, getFileDetail, getThumbnail } = useSelector(
    (state) => state.file
  );
  const { videoSetting } = useSelector((state) => state.videosetting)
  const { ipInfoData } = useSelector((state) => state.ipInfo);

  const [videoName, setVideName] = useState();
  const [fileSize, setFileSize] = useState();
  const videoRef = useRef(null);
  const [isFivePercentWatched, setIsFivePercentWatched] = useState(false);
  const [tempName, setTempName] = useState('')

  useEffect(() => {
    dispatch(getFileUrl(id));
    dispatch(getVideoSettings());
    dispatch(getVideoFileDetails(id)).then((response) => {
      if (response?.payload?.success === true) {
        setTempName(response?.payload?.data?.name)
        dispatch(getVideoSignedUrl(response?.payload?.data?.name))
      }
    })
  }, [id, dispatch]);

  useEffect(() => {
    if (gettedfileData.url != "") {
      setVideoUrl(gettedfileData.url);
      setVideName(gettedfileData.fileName);
      setFileSize(gettedfileData.fileSize);
    }
  }, [gettedfileData]);

  useEffect(() => {
    dispatch(getIpAddressService());
  }, [dispatch]);

  const handleTimeUpdate = () => {
    const video = playerRef.current;
    const currentTime = video?.currentTime;
    const duration = video.duration;

    if (duration > 0) {
      const percentageWatched = (currentTime / duration) * 100;
      if (percentageWatched >= 5 && !isFivePercentWatched) {
        setIsFivePercentWatched(true);
        const data = {
          id: id,
          ip: ipInfoData.ip,
          country: ipInfoData.country,
          code: ipInfoData.country,
        };
        dispatch(videoViewCount(data));
      }
    }
  };

  const playerRef = useRef(null);
  const [isPlyrLoaded, setIsPlyrLoaded] = useState(false);
  // const sources = [
  //   { url: videoUrl, type: 'video/mp4' },
  //   { url: videoUrl, type: 'video/mp4' }
  // ];

  const captions = [
    { url: '/path/to/captions-en.vtt', language: 'en', label: 'English', default: true },
    { url: '/path/to/captions-es.vtt', language: 'hi', label: 'Hindi' }
  ];

  useEffect(() => {
    let thumbnail = getFileDetail?.data?.thumbnail;
    dispatch(getVideoThumbnail(thumbnail))
    // Function to dynamically load a script
    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        document.body.appendChild(script);
      });
    };
    let hls;
    // Load both the Plyr and HLS.js scripts
    Promise.all([
      loadScript('https://cdn.plyr.io/3.7.2/plyr.js'), // Plyr CDN
      loadScript('https://cdnjs.cloudflare.com/ajax/libs/hls.js/1.1.5/hls.min.js') // HLS.js CDN
    ])
      .then(() => {
        if (window.Plyr && window.Hls) {
          const player = new window.Plyr(playerRef.current, {
            title: 'Example title',
            controls: [
              'play', 'progress', 'current-time', 'duration', 'mute', 'volume',
              'fullscreen', 'captions', 'settings', 'pip'
            ],
            settings: ['captions', 'quality', 'speed', 'loop'],
            captions: {
              active: true,
              update: true,
              language: 'en'
            },
            // quality: {
            //   default: 576,
            //   options: [4320, 2880, 2160, 1440, 1080, 720, 576, 480, 360, 240],
            //   forced: true,
            //   onChange: (e) => {
            //     console.log(e, 'anurag');
            //   },
            // },
            thumbnail: {
              enabled: true,
              pic_num: 184,
              width: 178,
              height: 100,
              col: 7,
              row: 7,
              offsetX: 0,
              offsetY: 0,
              urls: [
                'https://cdn.plyr.io/static/demo/thumbs/100p-00001.jpg',
                'https://cdn.plyr.io/static/demo/thumbs/100p-00002.jpg',
                'https://cdn.plyr.io/static/demo/thumbs/100p-00003.jpg',
                'https://cdn.plyr.io/static/demo/thumbs/100p-00004.jpg'
              ]
            },
          });
          var plyr = document.querySelector('.video-container>.plyr');
          var overlay = document.querySelector(".channel-icon")

          plyr.append(overlay)

          let chunkName = getFileDetail?.data?.chunkName;
          let quality = getFileDetail?.data?.quality[0]?.resolution;
          if (typeof getFileDetail?.data?.quality !== 'undefined' && getFileDetail?.data?.quality?.length > 0) {

            if (window.Hls.isSupported()) {
              hls = new window.Hls({
                xhrSetup: function (xhr, url) {
                  // If the URL is for a .ts file, fetch the signed URL
                  if (url.endsWith('.ts')) {
                    const filename = url.split('/').pop(); // Get the filename from the URL
                    dispatch(getVideoSignedUrl(`${chunkName}/${filename}`)).then((response) => {
                      const signedUrl = response?.payload?.data?.url; // Assuming the signed URL is in response payload
                      if (signedUrl && typeof signedUrl === 'string') {
                        xhr.open('GET', signedUrl, true); // Use the signed URL
                        xhr.send();
                      } else {
                        console.error("Invalid signed URL:", signedUrl);
                      }
                    });
                  }
                },
              });

              // Fetch signed URL for the master playlist and start loading the video
              dispatch(getVideoSignedUrl(`${chunkName}/${quality}.m3u8`)).then((response) => {
                const initialSignedUrl = response?.payload?.data?.url;
                if (initialSignedUrl && typeof initialSignedUrl === 'string') {
                  hls.loadSource(initialSignedUrl);
                  hls.attachMedia(playerRef.current);
                } else {
                  console.error("Invalid signed URL:", initialSignedUrl);
                }
              });
            } else if (player.canPlayType('application/vnd.apple.mpegurl')) {
              // const initialSignedUrl = await fetchSignedUrl(`${videoName}/720p.m3u8`); // Fetch signed URL for 720p
              // video.src = initialSignedUrl;
              // updateQualitySelector(qualities, currentQuality);
            }
          }

          setIsPlyrLoaded(true);

          // Cleanup on unmount
          return () => {
            if (player) player.destroy();
          };
        }
      })
      .catch(error => console.error(error));
  }, [videoUrl]); // Include videoUrl as dependency


  useEffect(() => {
    // Create a script element

    // Create the second script element for full-page popup
    const popupScript = document.createElement("script");
    popupScript.src = "//thubanoa.com/1?z=8225630"; // The full-page popup script URL
    popupScript.async = true;
    popupScript.setAttribute("data-cfasync", "false"); // Set any necessary attributes

    // Append the popup script to the body
    document.body.appendChild(popupScript);
    // Cleanup function: Remove the script when the component unmounts
    return () => {
      // Find the script we added and remove it
      if (popupScript) {
        document.body.removeChild(popupScript);
      }
    };
  }, [dispatch]); // Empty dependency array ensures this runs only once on mount

  // Get the current browser URL
  const browserUrl = window.location.href;

  const iframeEmbedCode = `<iframe src="${browserUrl}" width="800" height="600" allowfullscreen allowtransparency allow="autoplay" scrolling="no" frameborder="0"></iframe>`;

  return (
    <>
      <section className="video-single-sec  p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="heading-head mb-5 text-center">
                <span className="sub-heading mb-1">Welcome videos</span>
                <h2>{videoName}</h2>
              </div>
            </div>
            <div className="col-md-12">
              <div className="top-video">
                {/* <video
                  ref={videoRef}
                  onTimeUpdate={handleTimeUpdate}
                  width="100%"
                  height="700"
                  src={videoUrl}
                  controls
                ></video> */}
                <div className="video-container">

                  <div className="channel-icon">
                    <img src={videoSetting?.channelLogo} alt="Channel Icon" />
                  </div>
                  <video
                    ref={playerRef}
                    onTimeUpdate={handleTimeUpdate}
                    controls
                    data-plyr-config='{ "title": "Example Title" }'
                    aria-label='{"title": "Example Title"}'
                    poster={getThumbnail?.data?.url}
                  >
                    {/* {sources.map((source, index) => ( */}
                    <source src={videoUrl} type={'video/mp4'} />
                    {/* ))} */}
                    {captions.map((caption, index) => (
                      <track
                        key={index}
                        src={caption.url}
                        kind="subtitles"
                        srcLang={caption.language}
                        label={caption.label}
                        default={caption.default}
                      />
                    ))}
                  </video>
                </div>
              </div>
              <div className="mid-video mt-4 mb-4"></div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="video-btn-btm text-center">
                <a
                  href={videoUrl}
                  className="w-100 download-btn btn btn-primary mb-5"
                  download
                >
                  Download Video
                </a>
                <p className="subheading">Size: {fileSize} MB</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="field-copy-video-box">
                <label className="mb-2 w-100">Embed this video:</label>
                <textarea
                  className="w-100"
                  name="postContent"
                  value={iframeEmbedCode} // Pass the iframe code to textarea
                  rows={4}
                  cols={40}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VideoPlayer;
